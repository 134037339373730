var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{on:{"click":_vm.isShowClick}},[_vm._v("填报情况统计 "),_c('i',{class:_vm.isShow?'el-icon-arrow-up':'el-icon-arrow-down',staticStyle:{"font-weight":"bold"}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}]},[_c('div',{ref:"statisticsDate",staticStyle:{"height":"270px","background-color":"#fff","width":"100%"},attrs:{"id":"statisticsDate"}}),_c('div',{ref:"statisticsTime",staticStyle:{"height":"270px","background-color":"#fff","width":"100%"},attrs:{"id":"statisticsTime"}})]),_c('div',[_c('div',{staticClass:"content-box",staticStyle:{"margin-bottom":"50px"},attrs:{"id":"app"}},[_c('div',{staticClass:"col-8"},[_c('div',[(_vm.topMenu)?_c('ul',{staticClass:"nav bg-light",attrs:{"id":"menu"}},[_c('el-row',{attrs:{"type":"flex"}},[_c('el-col',{staticClass:"nav-item"},[_c('li',{on:{"click":function($event){return _vm.topMenuClick('#top')}}},[_c('i',{staticClass:"nav-li",class:_vm.topMenuValue === '#top'
                                                        ? 'nav-li-hover'
                                                        : ''}),_c('a',{staticClass:"nav-link h6 text-dark",class:_vm.topMenuValue === '#top'
                                                        ? 'a-hover'
                                                        : '',attrs:{"aria-current":"page","href":"#top"}},[_vm._v("常规问卷")])])]),_vm._l((_vm.topMenu),function(q,k){return _c('el-col',{key:k,staticClass:"nav-item"},[_c('li',{on:{"click":function($event){return _vm.topMenuClick('#' + q.title)}}},[_c('i',{staticClass:"nav-li",class:_vm.topMenuValue ===
                                                    '#' + q.title
                                                        ? 'nav-li-hover'
                                                        : ''}),_c('a',{class:_vm.topMenuValue ===
                                                    '#' + q.title
                                                        ? 'a-hover'
                                                        : '',attrs:{"aria-current":"page","href":'#' + q.title}},[_vm._v(_vm._s(q.title))])])])})],2)],1):_vm._e()]),_c('el-row',{staticClass:"echarts-content",attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":24}},[_c('ul',{staticStyle:{"list-style":"none","margin-top":"0","padding-left":"0px"}},[_c('a',{staticStyle:{"margin-left":"0px","padding":"0"},attrs:{"id":"top"}}),_vm._l((_vm.question),function(q,k){return _c('li',{key:k},[(q.code)?_c('h4',{staticClass:"text-dark",attrs:{"title":q.code,"id":q.title}},[(q.index)?_c('span',[_vm._v(_vm._s(q.index)+"、")]):_vm._e(),_vm._v(" ["+_vm._s(q.code)+"]"+_vm._s(q.title)+" ")]):_vm._e(),(
                                                q.input_type === 'radio' ||
                                                q.input_type === 'select'
                                            )?_c('div',{staticClass:"row",staticStyle:{"background-color":"#fff"}},[_c('el-row',{attrs:{"gutter":24}},[_c('el-col',{attrs:{"span":15}},[_c('div',{staticClass:"col-8"},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v(" 编码2 ")]),_c('th',{staticClass:"text-end"},[_vm._v(" 选项 ")]),_c('th',[_vm._v(" 小计 ")]),_c('th',[_vm._v(" 占本题参与企业比例 ")])])]),(
                                                                    q.input_options
                                                                )?_c('tbody',_vm._l((q
                                                                        .input_options
                                                                        .items),function(o){return _c('tr',{key:o.code},[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(o.code)+" ")]),_c('td',{staticClass:"text-end"},[_vm._v(" "+_vm._s(o.text)+" ")]),_c('td',{staticClass:"text-success handler",attrs:{"title":o.code},on:{"click":function($event){return _vm.listAnswerByName(
                                                                                q.code,
                                                                                o.value,
                                                                                o.score
                                                                            )}}},[_vm._v(" "+_vm._s(o.score)+" ")]),_c('td',[(
                                                                                o.proportion
                                                                            )?_c('div',[_c('div',[_c('div',[_c('el-progress',{attrs:{"show-text":"","stroke-width":16,"percentage":o.proportion}})],1)])]):_vm._e()])])}),0):_vm._e()])])]),_c('el-col',{attrs:{"span":9}},[_c('div',{staticClass:"col-4"},[_c('div',{staticStyle:{"padding":"5px"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},nativeOn:{"click":function($event){return _vm.downloadAnswer(q)}}},[_vm._v("下载答案1")]),_c('el-button',{attrs:{"type":"success","size":"small"},nativeOn:{"click":function($event){return _vm.uploadScore(q)}}},[_vm._v("上传得分2")])],1),_c('div',{ref:q.code,refInFor:true,staticStyle:{"height":"300px","margin-top":"-32px"},attrs:{"id":q.code}})])])],1)],1):(
                                                q.input_type === 'checkbox'
                                            )?_c('div',{staticClass:"row",staticStyle:{"background-color":"#fff"}},[_c('div',{staticClass:"col-12"},[_c('table',{staticClass:"table table-striped table-hover"},[_c('thead',{staticStyle:{"border":"1px"}},[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v(" 编码 ")]),_c('th',{staticClass:"text-end"},[_vm._v(" 选项 ")]),_c('th',[_vm._v("小计")]),_c('th',[_vm._v(" 占本题参与企业比例 ")])])]),(
                                                            q.input_options
                                                        )?_c('tbody',_vm._l((q
                                                                .input_options
                                                                .items),function(o){return _c('tr',{key:o.code},[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(o.code)+" ")]),_c('td',{staticClass:"text-end"},[_vm._v(" "+_vm._s(o.text)+" ")]),_c('td',{staticClass:"text-success handler",attrs:{"title":o.code},on:{"click":function($event){return _vm.listAnswerByName(
                                                                        q.code,
                                                                        o.value,
                                                                        o.score
                                                                    )}}},[_vm._v(" "+_vm._s(o.score)+" ")]),_c('td',[(
                                                                        o.proportion
                                                                    )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"progress"},[_c('el-progress',{attrs:{"show-text":"","stroke-width":16,"percentage":o.proportion}})],1)])]):_vm._e()])])}),0):_vm._e()])])]):(
                                                q.input_type === 'text' ||
                                                q.input_type ===
                                                    'textarea' ||
                                                q.input_type === 'number'
                                            )?_c('div',{staticClass:"row",staticStyle:{"background-color":"#fff"}},[_c('div',{staticClass:"col-12",staticStyle:{"max-height":"300px","overflow":"auto"}},[_c('table',{staticClass:"table table-striped table-hover"},[_c('thead',[_c('tr',{staticStyle:{"border-bottom":"1px"}},[_c('th',{staticClass:"text-center"},[_vm._v(" 内容 ")]),_c('th',[_vm._v("小计")])])]),_c('tbody',_vm._l((q.answer),function(a,k){return _c('tr',{key:k},[_c('td',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(
                                                                    a.value
                                                                )}}),(
                                                                    a.value
                                                                )?_c('td',{staticClass:"text-success handler",attrs:{"title":a.name},on:{"click":function($event){return _vm.listAnswerByName(
                                                                        q.code,
                                                                        a.value,
                                                                        a.score
                                                                    )}}},[_vm._v(" "+_vm._s(a.score)+" ")]):_vm._e()])}),0)])])]):_c('div',{staticClass:"bg-success",staticStyle:{"padding":"10px"},attrs:{"id":q.title},domProps:{"innerHTML":_vm._s(q.title_as)}})])})],2)])],1)],1)])]),_c('el-dialog',{attrs:{"title":"上传得分","visible":_vm.dialogVisible,"width":"30%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"action":"","on-preview":_vm.handlePreview,"on-remove":_vm.handleRemove,"file-list":_vm.fileList,"on-change":_vm.handelChange,"name":"cover","multiple":true,"auto-upload":false}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v(" 只能上传xlsx、xls文件，且不超过500kb ")])],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitUpdate}},[_vm._v("上 传")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }