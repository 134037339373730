<template>
    <div>
        <el-dialog
            title="预览信息"
            :visible.sync="dialogVisible"
            width="80%"
            :before-close="handleClose"
        >
            <div>
              内容
            </div>
            <!-- <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false"
                    >确 定</el-button
                >
            </span> -->
        </el-dialog>
    </div>
</template>
<script>
import {surveyBistablePlatformApi, surveyDownloadApi, surveyDownloadCompanyTelApi} from '@/api/analysisResultApi.js'
export default {
  data(){
    return{
      dialogVisible:false,
      apiName:'',
      code:''
    }
  },
  methods:{
    open(value,code){
      // console.log('value', value);
      // this.apiName = value
      if(value === 'surveyBistablePlatformApi'){
        this.surveyBistablePlatformFun()
      }else if(value === 'surveyDownloadApi'){
        this.surveyDownloadFun(code)
      }else{
        this.surveyDownloadCompanyTelFun()
      }
      // this.code = code
      this.dialogVisible = true
    },
    // 双稳平台推送
    async surveyBistablePlatformFun(){
      var res = await surveyBistablePlatformApi(this.$route.query.id)
      console.log('双稳平台推送res', res);
    },
    // 下载所有问卷  问卷数据（编码）
    async surveyDownloadFun(code){
      var params = {
        code
      }
      var res = await surveyDownloadApi(this.$route.query.id, params)
      console.log('下载所有问卷res', res);
    },
    // 下载企业及联系方式
    async surveyDownloadCompanyTelFun(){
      var res = await surveyDownloadCompanyTelApi(this.$route.query.id)
      console.log('下载企业及联系方式res', res);
    },
    handleClose(){
      this.dialogVisible=false
    }
  }
}
</script>
<style scoped></style>
