<template>
    <div>
        <el-row :gutter="24">
            <el-col
                :span="4"
                style="background-color: #fff"
                class="left-box"
            >
               <Left :id="id" @search="search"></Left>
            </el-col>
            <el-col
                :span="16"
            >
            <Content :id="id" ref="content"></Content>
            </el-col>
            <el-col
                :span="4"
                style="background-color: #fff"
                class="right-box"
            >
               <Right :id="id"></Right>
            </el-col>
        </el-row>
        <div class="flex">
            <div class="btn-group-vertical" style="width: 100%">
                <el-button
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="downloadAnalysis()"
                >
                    <i class="fa fa-download"></i> 下载分析结果
                </el-button>
                <el-button
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="downloadCompany()"
                >
                    <i class="fa fa-download"></i> 下载参与企业及联系方式
                </el-button>
                <el-button
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="downloadnoCompany()"
                >
                    <i class="fa fa-download"></i> 下载未填报企业及联系方式
                </el-button>
                <el-button
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="doDownloadAnswer(0)"
                >
                    <i class="fa fa-download"></i> 下载所有问卷数据
                </el-button>
                <el-button
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="doDownloadAnswer(1)"
                >
                    <i class="fa fa-download"></i> 下载所有问卷数据（编码）
                </el-button>
                <el-button
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="downloadinformationmanagement()"
                >
                    <i class="fa fa-download"></i> 双稳平台推送信息管理
                </el-button>
            </div>
        </div>
        <Model ref="model"></Model>
    </div>
</template>
<script>
import Left from './components/left.vue'
import Content from './components/content.vue'
import Right from './components/right.vue'
import Model from './components/model.vue'
import {
    getNewIdApi,
} from '@/api/analysisResultApi'
export default {
    components:{Left,Content,Right, Model},
    data() {
        return {
            id: null,
            
        }
    },
    async created() {
        if (!this.$route.query.id) {
            this.id = await this.getNewIdFun()
        } else {
            this.id = this.$route.query.id
        }
    },
    mounted() {
    },
    computed: {
    },
    methods: {
        // 左侧数据返回的送搜索数据
        search(value){
            // console.log('value', value);
            this.$refs.content.open(value)
            // this.leftParams= value

        },
          // 获取最新问卷ID
        async getNewIdFun() {
            var res = await getNewIdApi()
            return res.code === 200 ? res.data : null
        },
        // 下载分析结果
        downloadAnalysis() {},
        // 下载参与企业及联系方式
        downloadCompany() {
            this.$refs.model.open('surveyDownloadCompanyTelApi')
        },
        // 下载未填报企业及联系方式
        downloadnoCompany() {},
        // 下载所有问卷数据:0   下载所有问卷数据（编码）:1
        doDownloadAnswer(code) {
            this.$refs.model.open('surveyDownloadApi', code)
        },
        // 双稳平台推送信息管理
        downloadinformationmanagement() {
            this.$refs.model.open('surveyBistablePlatformApi')
        },
    },
}
</script>
<style scoped>
.company {
    background-color: rgba(242, 242, 242, 1);
    border-radius: 6px;
    margin-bottom: 10px;
    padding: 10px;
}
.company-name {
    font-size: 12px;
    color: #409eff;
}
.company-rate {
    font-size: 12px;
    color: #19be6b;
}
.company-uscc,
.company-city {
    font-size: 12px;
    color: #333;
    word-wrap: break-word;
}
.company-dateTime {
    font-size: 12px;
    color: #333;
}
.company-noTime {
    font-size: 12px;
    color: #ff0000;
}
.left-box {
    height: 100%;
    /* height: calc(100vh - 180px); */
    overflow-y: auto;
    overflow-x: auto;
    margin-bottom: 48px;
}
.left-box::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}
.left-box::-webkit-scrollbar-track {
    background-color: #fff;
}
.left-box::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 7px;
}
.right-box {
    height: 100%;
    /* height: calc(100vh - 180px); */
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 48px;
    padding-bottom: 10px;
}
.right-box::-webkit-scrollbar {
    width: 7px;
}
.right-box::-webkit-scrollbar-track {
    background-color: #fff;
}
.right-box::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 7px;
}
.nav-item {
    list-style-type: none;
}
a {
    color: #000;
    text-decoration: none;
    margin-left: 7px;
}
.a-hover {
    color: rgb(24, 144, 255);
}
.nav-li {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: #474747;
}
.nav-li-hover {
    background-color: rgb(24, 144, 255);
}
.table {
    width: 100%;
    border-collapse: collapse;
    /* background-color: #fff; */
}
.text-center {
    text-align: center;
}
tr {
    height: 40px;
}
th {
    padding: 0;
    background-color: rgba(250, 250, 250, 1);
}
td {
    border-bottom: 1px solid #ccc;
    padding: 0;
}
::v-deep .el-progress {
    display: flex;
}
.flex {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 18px;
    padding: 10px;
    background-color: #fff;
    text-align: center;
}
.content-box {
}
.echarts-content {
    height: calc(100vh - 270px);
    overflow: auto;
}
.echarts-content::-webkit-scrollbar {
    width: 7px;
}
.echarts-content::-webkit-scrollbar-track {
    background-color: #fff;
}
.echarts-content::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 7px;
}
</style>
