<template>
  <div>
      <!-- <el-row :gutter="24">
          <el-col
              :span="4"
              element-loading-text="拼命加载中"  v-loading="rightLoading"
               
              style="background-color: #fff"
              class="right-box"
          > -->
              <div>
                  <el-form ref="form" :model="form">
                      <el-form-item label="参与企业：">
                          <el-input clearable v-model="form.name" @change="searchChange"></el-input>
                      </el-form-item>
                      <el-form-item label="填报状态：">
                          <el-select
                              v-model="form.type"
                              placeholder="请选择"
                              style="width: 100%"
                              clearable
                              @change="searchChange"
                          >
                              <el-option
                                  v-for="item in fillingStatus"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value"
                              >
                              </el-option>
                          </el-select>
                      </el-form-item>
                  </el-form>
              </div>
              <div>
                  <div
                      v-for="(item, index) in companyList"
                      :key="index"
                      class="company"
                  >
                      <el-row type="flex" :gutter="24">
                          <el-col :span="14" class="company-name">{{
                              item.name
                          }}</el-col>
                          <el-col class="company-rate" :span="10"
                              ><div @click="percentageClick(item.uscc)">填报率{{item.percentage}}%</div></el-col
                          >
                      </el-row>
                      <el-row
                          type="flex"
                          :gutter="24"
                          style="margin-top: 5px"
                      >
                          <el-col :span="14" class="company-uscc">{{
                              item.uscc
                          }}</el-col>
                          <el-col :span="10" class="company-city"
                              >{{ item.city }}{{ item.country }}</el-col
                          >
                      </el-row>
                      <div style="margin-top: 5px; margin-bottom: 5px">
                          <span v-if="item.dateTime" class="company-dateTime"
                              >填报时间：{{ item.dateTime }}</span
                          >
                          <span v-else class="company-noTime"
                              >本次未填报</span
                          >
                      </div>
                      <!-- <el-button
                          size="mini"
                          type="primary"
                          icon="el-icon-phone"
                          >拨打电话</el-button
                      > -->
                  </div>
                  <el-pagination
                      background
                      layout="prev, pager, next"
                      :total="companyTotal"
                      :page-size="pageSize"
                  >
                  </el-pagination>
              </div>
          <!-- </el-col>
      </el-row> -->
      <companyModel ref="companyModel"></companyModel>
  </div>
</template>
<script>
import companyModel from './companyModel.vue'
import {
  tableCompanyApi
} from '@/api/analysisResultApi'
export default {
  components:{companyModel},
  props:{
    id:{
      type:String
    }
  },
  data() {
      return {
          // id: null,
          pageSize:10,
          companyList: [],
          form: {
                name: '',
                type: 0,
            },
          fillingStatus: [
              {
                  value: 0,
                  label: '全部',
              },
              {
                  value: 2,
                  label: '未填报',
              },
              {
                  value: 1,
                  label: '已填报',
              },
          ],
          companyTotal: null,
         
      }
  },
  async created() {
      // this.id = this.$route.query.id
      this.companyDateFun() // 右侧企业
  },
  mounted() {
  },
  computed: {
     
  },
  methods: {
      // 根据企业编码查询问卷填报
      async percentageClick(uscc){
          this.$refs.companyModel.open(uscc)
      },
    
      // 分页插件事件
      callFather(parm) {
          this.companyDateFun(parm.currentPage, parm.pageSize)
      },
      // 搜索
      searchChange(){
        this.form.type = this.form.type ? this.form.type:0
        this.companyDateFun()
      },
      // 获取公司
      async companyDateFun(currentPage, pageSize) {
          var start = currentPage?(currentPage * pageSize) -pageSize  :0
          var params = {
              start: start,
              length: pageSize ? pageSize : 10,
              draw: 1
          }
          var p = Object.assign(params, this.form)
          var res = await tableCompanyApi(this.id, this.form.type, p)
          this.companyList = res.data
          this.companyTotal = res.recordsTotal
          this.rightLoading = false
      },
     
  },
}
</script>
<style scoped>
.company {
  background-color: rgba(242, 242, 242, 1);
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 10px;
}
.company-name {
  font-size: 12px;
  color: #409eff;
}
.company-rate {
  font-size: 12px;
  color: #19be6b;
}
.company-uscc,
.company-city {
  font-size: 12px;
  color: #333;
  word-wrap: break-word;
}
.company-dateTime {
  font-size: 12px;
  color: #333;
}
.company-noTime {
  font-size: 12px;
  color: #ff0000;
}
.left-box {
  height: 100%;
  /* height: calc(100vh - 180px); */
  overflow-y: auto;
  overflow-x: auto;
  margin-bottom: 48px;
}
.left-box::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.left-box::-webkit-scrollbar-track {
  background-color: #fff;
}
.left-box::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 7px;
}
.right-box {
  height: 100%;
  /* height: calc(100vh - 180px); */
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 48px;
  padding-bottom: 10px;
}
.right-box::-webkit-scrollbar {
  width: 7px;
}
.right-box::-webkit-scrollbar-track {
  background-color: #fff;
}
.right-box::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 7px;
}
.nav-item {
  list-style-type: none;
}
a {
  color: #000;
  text-decoration: none;
  margin-left: 7px;
}
.a-hover {
  color: rgb(24, 144, 255);
}
.nav-li {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: #474747;
}
.nav-li-hover {
  background-color: rgb(24, 144, 255);
}
.table {
  width: 100%;
  border-collapse: collapse;
  /* background-color: #fff; */
}
.text-center {
  text-align: center;
}
tr {
  height: 40px;
}
th {
  padding: 0;
  background-color: rgba(250, 250, 250, 1);
}
td {
  border-bottom: 1px solid #ccc;
  padding: 0;
}
::v-deep .el-progress {
  display: flex;
}
.flex {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 18px;
  padding: 10px;
  background-color: #fff;
  text-align: center;
}
.content-box {
}
.echarts-content {
  height: calc(100vh - 270px);
  overflow: auto;
}
.echarts-content::-webkit-scrollbar {
  width: 7px;
}
.echarts-content::-webkit-scrollbar-track {
  background-color: #fff;
}
.echarts-content::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 7px;
}
</style>
